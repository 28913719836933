import { render, staticRenderFns } from "./404.vue?vue&type=template&id=4082d54f&scoped=true"
import script from "./404.vue?vue&type=script&lang=js"
export * from "./404.vue?vue&type=script&lang=js"
import style0 from "./404.vue?vue&type=style&index=0&id=4082d54f&prod&lang=less&scoped=true"


/* normalize component */
import normalizer from "!../../node_modules/.pnpm/vue-loader@15.11.1_cache-loader@4.1.0_css-loader@3.6.0_webpack@4.47.0/node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "4082d54f",
  null
  
)

export default component.exports